.container {
    background-color: #3c1e6e; 
    width: 100vw; 
    height: 15vh;
    position: fixed;
    top: 0;
    overflow: hidden;
    z-index: 9;
}

.img {
    height: 10vh;
    margin-top: 2.5vh;
}