.container {

    padding: 3vh 5vw;
    border-radius: 15px;
    background-color: white;
    text-align: center;
    margin: 0 auto;

}

.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.img {
    vertical-align: middle;
    width: 200px;
}