.card {
    width: 70vw;
    padding: 2.2vh 8vw; 
    font-size: 18px; 
    color: #3c1e6e;
    border-radius: 10px;
    background-color: white;
    border: none;
    font-weight: bold;
    cursor: pointer;
    display: inline-block;
    transition: all 0.5s ease-out;
}

.flex {
    padding: 18px 30px; 
    font-size: 18px; 
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    display: inline-block; 
    transition: all 0.5s ease-out;
}
  
.active {
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    background-color: #3c1e6e;
    color: white;
}

.inactive {
    color: #3c1e6e;
    background-color: white;
}

@media (min-width: 479px) {
    .card {
      max-width: 450px;
      padding: 40px 20px; 
      font-size: 20px; 
      text-align: center;
    }
  }