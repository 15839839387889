.container {
    display: inline-block;
    cursor: pointer;
    padding: 4.2vh 5vw;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;
}
  
.bar1, .bar2, .bar3 {
    width: 30px;
    height: 5px;
    background-color: white;
    margin: 7px 0;
    transition: 0.4s;
}