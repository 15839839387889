.heading {
    text-align: center;
    font-size: 10vw;
}

/* responsive for big screens */
@media (min-width: 479px) {
    .heading {
        font-size: 50px;
    }
  }