.container {
    text-align: center;
    padding: 3vh 5vw;
    border-radius: 15px;
    background-color: white;

}

.img {
    width: -webkit-fill-available;
}