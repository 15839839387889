.container {
    top: 17%;
    /* right: 0; */
    left: 60%;
    position: absolute;
    z-index: 2;
    margin-top: 4vh;
    margin-right: 10vw;
    width: 100%;
}

.stack_style {
    font-size: 0.8em !important;
}

.active {
    color: white;
}

.inactive {
    color: #3c1e6e;
}

a {
    font-size:unset;
}

.fad {
    font-size: 40px;
    padding-right: 10px;
}

  /* responsive for big screens */
  @media (min-width: 479px) {
    .container {
        display: flex;
        /* position: absolute;
        display: flex;
        z-index: 2;
        margin-top: 4vh;
        margin-right: 10vw;
        width: 94vw;
        right: 0; */
        /* font-size: 2rem; */
        /* position: absolute; */
        /* top: 15%; */
        /* left: 50%; */
        /* transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%); */
        /* cursor: pointer; */
        /* width: 20%; */
        /* z-index: 2; */
    }
    /* .image {
        width: 30px;
        float: right;
        padding-right: 3vw;
    } */

  }
