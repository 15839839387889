.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
}
  
.modal-main {
    position:fixed;
    background: #3c1e6e;
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius: 10px;
}

.modal-main > p {
    padding: 7% 7% 3% 7%;
    text-align: center;
}

.modal-button {
    display: flex;
    justify-content: center;
    gap: 5%;
    padding-bottom: 3%;
}
  
.display-block {
    display: block;
}
  
.display-none {
    display: none;
}
