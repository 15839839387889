.card {
    width: 70vw;
    height: 16vh;
    padding-left: 4vw;
    padding-right: 8vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    border-radius: 10px;
    background-color: white;
    border: none;
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: all 0.5s ease-out;
}

  
  .card:hover {
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    background-color: #3c1e6e;
    color: white !important;
  }

  .card:hover .text {
      color: white;
  }

  .text {
      text-align: left;
      color: #3c1e6e;
      font-size: 5vw;
      margin-left: 28vw;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
  }

  /* .parent {
      position: relative;
  } */
  
  .verticalcenter {
    width: 70vw;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #3c1e6e;
    font-weight: 500;
  }

  .tile img {
      height: 14vh;
      width: 25vw;
      overflow: hidden;
      float: left;
      margin: auto;
      border-radius: 10%;
  }

  /* responsive for big screens */
@media (min-width: 479px) {
  .card {
    max-width: 450px;
    padding: 40px 20px; 
    font-size: 20px; 
  }
  .text {
    font-size: 20px;
    margin-left: 200px;
  }
  .verticalcenter {
    max-width: 450px;
  }
  .tile img {
    height: 150px;
    width: 150px;
    overflow: hidden;
    float: left;
    margin: auto;
    border-radius: 10%;
  }
}