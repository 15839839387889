.container {
    top: 17%;
    /* right: 0; */
    left: 60%;
    position: absolute;
    z-index: 2;
    margin-top: 4vh;
    margin-right: 10vw;
    width: 100%;
}

.stack_style {
    font-size: 0.8em !important;
}

.active {
    color: white;
}

.inactive {
    color: #3c1e6e;
}

a {
    font-size:unset;
}

.fad {
    font-size: 40px;
    padding-right: 10px;
}

  /* responsive for big screens */
  @media (min-width: 479px) {
    .container {
        display: flex;
    }

  }
