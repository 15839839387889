* {
  font-family: "Roboto";
  font-weight: 300;
  font-size: 5vw;
  color: white;
  -webkit-tap-highlight-color: transparent;
}

/* To hide number field increment decrement arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.italic {
  font-style: italic;
}

.margin-top-1 {
  margin-top: 1vh;
}

.margin-top-1-5 {
  margin-top: 1.5vh;
}

.margin-top-2 {
  margin-top: 2vh;
}

.margin-top-3 {
  margin-top: 3vh;
}

.margin-top-5 {
  margin-top: 5vh;
}

.margin-top-10 {
  margin-top: 10vh;
}

.margin-top-15 {
  margin-top: 15vh;
}

.margin-top-20 {
  margin-top: 20vh;
}

.padding-top-5 {
  padding-top: 5vh;
}

.pointer {
  text-decoration: underline;
  cursor: pointer;
}

h1 {
  font-size: 2rem;
}

.toolkit-title {
  font-size: 1.2rem;
  font-weight: 400;
}

.verify-code-label {
  font-size: 1.1rem;
  padding-left: 5vw;
  padding-right: 5vw;
}

h2 {
  font-weight: bold;
}

/* a:hover {
    all: initial;
} */
/* p > a, p > a > u, p > a > u > ins :- if this selector use only those direct children are effected */
/* p a, p a * :- this selector select a child of p and all the child of a in p */
p a, p a * {
  color: #85C1E9;
}

p > img {
  max-width: 100%;
  max-height: 100vh;
  width: auto !important;
  height: auto !important;
  margin: auto;
}

input {
  width: 70vw;
  padding: 15px 7.5vw 15px 7.5vw;
  background-color: #3c1e6e;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  color: white;
}

input::placeholder {
  font-size: 5vw;
}

input:focus::placeholder {
  color: transparent;
}

textarea {
  width: 70vw;
  padding: 2vh 7.5vw 2vh 7.5vw;
  background-color: #3c1e6e;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  color: white;
}

textarea:focus::placeholder {
  color: transparent;
}

button {
  padding: 2vh 8vw 2vh 8vw;
  font-size: 1.4rem;
  color: #3c1e6e;
  border-radius: 10px;
  background-color: white;
  border: none;
  font-weight: bold;
  cursor: pointer;
}
.center {
  text-align: center;
}

.margin-both-side {
  margin-left: 7vw;
  margin-right: 7vw;
}

.auth-surrounding {
  margin-top: 15vh;
}

.intro-div-surrounding {
  margin-left: 7vw;
  margin-right: 7vw;
  margin-top: 15vh;
  padding-bottom: 15vh;
  overflow: hidden;
}

.bold {
  font-weight: 400;
}

.team-body {
  margin: 0 auto;
  max-width: 400px;
  padding: 15vh 7vw;
}

.feedback-section-description {
  font-size: 3.5vw;
  font-style: italic;
}

#suggestion {
  height: 120px;
}

.team-img-link-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.team-img img {
  /* width: auto !important;
    width: 100%;
    max-width: 40vw;
    float: left;
    clear: both; */
  width: 160px;
  height: 160px;
  object-fit: cover;
  object-position: top;
  border: inset;
  border-radius: 10px;
}

.team-profile-link {
  display: flex;
  flex-direction: column;
}

/* .case-study-img {
    height: 50vh;
    text-align: center;
    background-color: #3c1e6e;
    padding-bottom: 5vh;
    overflow: hidden;
    box-shadow: inset 0 5px 10px rgb(60 30 95)
} */

/* .case-study-img img {
    width: -webkit-fill-available;
}
*/

.case-study-question-card {
  /* margin-left: 7.5vw; */
  margin: auto;
  height: 75vh;
  width: 85vw;
  max-width: 450px;
  border-radius: 10px;
  background-color: white;
  border: none;
  overflow: scroll;
  /* position: fixed; */
}

.case-study-question-card > .title > .text {
  margin-left: 10px;
  /* padding-left: 10px;
  padding-right: 10px; */
}

.case-study-question-card .question {
  padding-left: 35px;
  padding-right: 35px;
}

.case-study-question-card .title {
  padding-top: 8px;
  padding-bottom: 8px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 2.5vh;
  background-color: #eee;
  height: 20px;
  width: inherit;
  max-width: inherit;
  /* width: 77vw;
  max-width: 450px; */
  position: fixed;
}

.case-study-question-card .title .text {
  color: #3c1e6e;
  font-weight: bold;
  /* npmwidth: 74vw; */
  float: left;
}

.case-study-question-card > .title > .btn-close {
  float: right;
  margin-right: 10px;
  cursor: pointer;
}

.case-study-question-card .data .question {
  color: #3c1e6e;
}

.case-study-question-card .data {
  padding-top: 50px;
}

.case-study-question-card .bottom {
  height: 6vh;
  background-color: white;
  z-index: 2;
  margin-top: -28vh;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.case-study-question-card::-webkit-scrollbar {
  display: none;
}
.case-study-question-card {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.case-study-filter-div {
  display: flex;
  max-width: 400px;
  flex-wrap: wrap;
  grid-gap: 5px;
  margin: 0 auto;
}

.err {
  position: absolute;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.hide-elm {
  visibility: hidden;
}

.hide-shrink-elm {
  display: none;
}

/* Introduction screen related styles go below */
.intro-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100vh;
  margin: auto;
}
.intro-card {
  /* position: absolute; */
  height: 30rem;
  width: 18rem;
  /* margin: 1rem; */
  margin-top: 15vh;
  background-color: #3c1e6e;
  border-radius: 1rem;
  box-sizing: border-box;
  user-select: none;
  overflow: scroll;
  /* border-bottom-style: double;
  border-bottom-color: #000000; */
  padding-bottom: 20px;
  box-shadow: 0 0 10px 3px #aaa;
}

.intro-card:hover {
  cursor: pointer;
}

.intro-nav {
  text-align: center;
  margin-top: -50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 12px;
}

.intro-card > .intro-div-surrounding {
  margin-top: 0px;
}

.intro-card > .back-button-container, .intro-card > a > .home-button {
  display: none;
}

/* responsive for small screens */
@media (max-width: 479px) {
  .center-vertical,
  .center-vertical-menu {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -27%);
    transform: translate(-50%, -27%);
  }
  .case-study-outer {
    width: 100vw;
  }
}

/* responsive for big screens */
@media (min-width: 479px) {
  * {
    font-size: 20px;
  }
  input {
    max-width: 350px;
    padding: 20px 40px 20px 40px;
    font-size: 20px;
  }
  input::placeholder {
    font-size: 20px;
  }
  h1 {
    font-size: 50px;
  }
  button {
    padding: 10px 20px 10px 20px;
    /* font-size: 40px; */
  }
  .center-vertical {
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
  .center-vertical-menu {
    margin-top: 20vh;
    margin-bottom: 5vh;
  }
  .team-body input {
    max-width: 320px;
  }
  .team-body textarea {
    max-width: 320px;
    padding: 20px 40px;
  }
  .feedback-section-description {
    font-size: 18px;
  }
  .about-logo {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .big-screen-top-margin {
    margin-top: 20vh;
  }
  .intro-card {
    /* position: absolute; */
    height: 80vh;
    width: 75%;
    /* margin: 1rem; */
    margin-top: 15vh;
    /* border-bottom-style: double;
    border-bottom-color: #000000; */
    padding-bottom: 20px;
    /* overflow: hidden; */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .intro-card::-webkit-scrollbar {
    display: none;
  }
  .intro-div-surrounding > .about-logo {
    flex-direction: column;
  }
}

.case-study-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.case-study-img {
  float: left;
  width: 100%;
  height: 450px;
  object-fit: fill;
}

.case-study-container {
  position: relative;
  width: 100%;
  max-width: 479px;
}

.case-study-overlay {
  position: absolute;
  z-index: 2;
  color: #f5f5f5;
  font-size: 25px;
  text-align: right;
  cursor: pointer;
  border-radius: 10rem;
  background-color: transparent;
  border: transparent;
  width: 95%;
  padding-top: 15px;
}

.case-study-navigation {
  position: absolute;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  color: #f5f5f5;
  font-size: 50px;
  width: 90%;
  margin-left: 5%;
  margin-top: 100px;
}

.personal-research-navigation {
  margin-top: 150px;
}

.case-study-navigation-active {
  /* color: #3c1e6e; */
  color: white;
  cursor: pointer;
}

.case-study-navigation-inactive {
  color: rgba(255, 255, 255, 0.1);
}

.case-study-navigation > .right > .fa,
.case-study-navigation > .left > .fa {
  /* font-size: 70px; */
  font-size: 9em;
}

.case-study-text-container {
  width: 90vw;
  max-width: 500px;
  margin-left: 7vw;
  margin-right: 7vw;
  margin-top: 5vh;
  padding-bottom: 15vh;
  overflow: hidden;
  /* if the text or link continuously go it is hidded the below will break the word */
  overflow-wrap: break-word; 
}

.case-study-filter-icon {
  width: 82vw;
  text-align: right;
}

.case-study-filter-icon i {
  cursor: pointer;
}

.case-study-question-card > .title > .btn-close > .fa-times-circle-o {
  color: #3c1e6e;
}

.case-study-full-screen > div {
  z-index: 100;
  position: fixed;
  right: 0px;
  background-color: white;
  border-radius: 0 0 0 100%;
  width: 11vmin;
  height: 11vmin;
  text-align: center;
}

.case-study-full-screen > div > i {
  font-size: 24px;
  cursor: pointer;
  color: #3c1e6e;
  margin-top: 20%;
}

.case-study-full-screen > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #3c1e6e;

}

#doNotShowCheck {
  width: 30px;
  margin-left: 10%;
}

.shadow {
  position: fixed;
  width: 100%;
  height: 1%;
  -webkit-box-shadow: inset -1px 5px 5px -3px #000000;
  box-shadow: inset -1px 5px 5px -3px #000000;
  top: 15vh;
  left: 0;
}

.menu-inactive-icon {
  font-size: 40px !important;
}

.css-10p2e9r {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

/* style for developed by rune digital section */
.rd-caption, .rd-caption a {
  text-align: center;
  font-size: 1em;
  font-family: agencyfb;
  letter-spacing: 0.05em;
  text-shadow: 0.3vw 0.5vw 5px #000000;
}

/* style for character count comes under input in Feedback Page */
.count-label {
  text-align: right;
  float: right;
}

/* style for re captcha to be in center of the page */
.re-captcha {
  display: inline-block;
  color: #3c1e6e;
}
.rc-anchor-light {
  color: #3c1e6e !important;
}
