.description {
    font-size: 6.5vw;
    font-weight: bold;
}

/* responsive for big screens */
@media (min-width: 479px) {
    .description {
        font-size: 30px;
    }
  }