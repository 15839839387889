.container {
    display: inline-block;
    cursor: pointer;
    padding: 2.5vh 5vw;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
}
  
.bar1, .bar2 {
    width: 22px;
    height: 5px;
    background-color: white;
    margin: 18px 0;
    transition: 0.4s;
}

.bar1 {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg) translate(0px, 8px);
}

.bar2 {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg) translate(0px, -8px);
}