.card {
  width: 70vw;
  padding: 2.2vh 8vw; 
  font-size: 6.5vw; 
  color: #3c1e6e;
  border-radius: 10px;
  background-color: white;
  border: none;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  transition: all 0.5s ease-out;
}

.card:active {
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  /* border: 1px solid #cccccc; */
  background-color: #3c1e6e;
  color: white;
}

.selector {
  width: 70vw;
  height: 7vh;
  padding: 1vh 8vw;  
  border-radius: 10px;
  background-color: white;
  border: none;
  cursor: pointer;
  display: inline-block;
  position: relative;

}

.verticalcenter {
  width: 70vw;
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #3c1e6e;
  font-weight: 500;
}

/* responsive for big screens */
@media (min-width: 479px) {
  .card {
    max-width: 450px;
    padding: 40px 20px; 
    font-size: 20px; 
    color: #3c1e6e;
    border-radius: 10px;
    background-color: white;
    border: none;
    font-weight: bold;
    cursor: pointer;
    display: inline-block;
    transition: all 0.5s ease-out;
    text-align: center;
  }
}