.card {
    /* padding: 2.2vh 8vw;  */
    padding: 18px 30px; 
    font-size: 18px; 
    border-radius: 45px;
    text-align: center;
    border: none;
    font-weight: bold;
    cursor: pointer;
    display: inline-block;
    transition: all 0.5s ease-out;
}
  
.active {
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    background-color: #3c1e6e;
    color: white;
}

.inactive {
    color: #3c1e6e;
    background-color: white;
}