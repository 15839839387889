.error {
    width: 70vw;
    /* height: 5vh; */
    background-color: white;
    border-radius: 10px;
    display: inline-block;
    padding: 3vh 7.5vw 5vh 7.5vw;
    text-align: left;
}
.header {
    color: red;
    font-size: 6.5vw;
    font-weight: bold;
    text-align: center;
}
.content {
    color: #3c1e6e;
    text-align: left;
    font-size: 5vw;
    text-align: center;
}

@media (min-width: 479px) {
    .error {
        max-width: 350px;
        padding: 20px 40px 20px 40px;
        font-size: 20px;
    }
    .header {
        font-size: 20px;
    }
    .content {
        font-size: 20px;
    }
  }