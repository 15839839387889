.container {
  display: inline-block;
  /* cursor: pointer; */
  padding: 4.2vh 5vw;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}

.fa {
  background-color: white;
  font-size: 40px;
}
